import axios from "axios"

const state = {
  member: null,
  members: [],
  selectedMember: null,
}

// getters
const getters = {
  member: (state) => state.member,
  members: (state) => state.members,
  selectedMember: (state) => state.selectedMember,
}

// actions
const actions = {
  getAll(context) {
    return axios
      .get(context.rootGetters.API.baseUrl + "members", context.rootGetters.axiosConfig)
      .then((response) => context.commit("SET_ALL", response.data))
      .catch((error) => console.log(error))
  },
  login(context, payload) {
    console.log("login")
    console.log(context.rootGetters.API.baseUrl + "members/auth")
    return axios
      .post(context.rootGetters.API.baseUrl + "members/auth", {
        email: payload.email,
        password: payload.password,
      })
      .then((response) => {
        console.log("axios login resolved")
        console.log(response.data)
        let payload = {
          member: response.data.member,
          token: response.data.token,
        }
        if (!response.data.success) {
          console.log("login error")
          context.commit("SET_LOGIN_ERROR", response.data.message)
        } else {
          console.log("login success")
          context.commit("LOGIN", payload.member)
          context.commit("SET_TOKEN", payload.token, { root: true })
        }
        return response
      })
      .catch((error) => {
        console.log(error)
        this.errored = true
      })
  },
  register(context, payload) {
    return axios
      .post(context.rootGetters.API.baseUrl + "members/register", payload)
      .then((response) => {
        console.log("axios register resolved")
        return response
      })
      .catch((error) => {
        console.log(error)
        this.errored = true
      })
  },
  update(context, payload) {
    console.log("update member")
    return axios
      .patch(
        context.rootGetters.API.baseUrl + "members/" + payload._id,
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  updatePassword(context, payload) {
    console.log("updatePassword")
    return axios
      .patch(
        context.rootGetters.API.baseUrl + "members/pwd/" + payload._id,
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  uploadImage(context, payload) {
    console.log("uploadImage")
    return axios
      .post(
        context.rootGetters.API.baseUrl + "members/upload",
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        // success callback
        console.log(response)
        console.log("UPLOADED")
      })
      .catch((error) => {
        console.log(error)
      })
  },
  create(context, payload) {
    console.log("create member")
    return axios
      .put(context.rootGetters.API.baseUrl + "members", payload)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
}

// mutations
const mutations = {
  LOGIN(state, payload) {
    console.log("LOGIN")
    console.log(payload)
    state.memberId = payload._id
    state.member = payload
  },
  LOGOUT(state) {
    state.memberId = null
    state.member = null
  },
  SET_LOGIN_ERROR(state, data) {
    state.loginError = data
  },
  SET_ALL(state, data) {
    state.members = data
  },
  SET_MEMBER(state, data) {
    state.member = data
  },
  SET_SELECTED_MEMBER(state, data) {
    state.selectedMember = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
