import { createStore } from "vuex"
import members from "./modules/members"
import exercises from "./modules/exercises"
import workouts from "./modules/workouts"
import memberLogs from "./modules/memberLogs"

const debug = process.env.NODE_ENV !== "production"

const store = createStore({
  state: {
    API: {
      baseUrl: process.env.VUE_APP_API_URL,
      imageUrl: process.env.VUE_APP_IMAGE_URL,
    },
    CHANGE_TIMEOUT_MS: 300,
    axiosConfig: {},
    busy: false,
  },
  getters: {
    API: (state) => state.API,
    axiosConfig: (state) => state.axiosConfig,
    busy: (state) => state.busy,
    CHANGE_TIMEOUT_MS: (state) => state.CHANGE_TIMEOUT_MS,
  },
  actions: {
    //
  },
  mutations: {
    SET_BUSY(state, data) {
      state.busy = data
    },
    SET_TOKEN(state, data) {
      console.log("SET_TOKEN")
      console.log(data)
      state.axiosConfig = {
        headers: { Authorization: "Bearer " + data },
      }
      console.log(state.axiosConfig)
    },
    SET_STEP(state, data) {
      state.step = data
    },
  },
  modules: {
    members,
    exercises,
    workouts,
    memberLogs,
  },
  strict: debug,
})

export default store
