<template>
  <div class="workouts">
    <div class="workouts-tabs">
      <div
        class="workouts-tab"
        :class="{ active: ageFilter === 'KIDS' }"
        @click="ageFilter = 'KIDS'"
      >
        Kinder
      </div>
      <div
        class="workouts-tab"
        :class="{ active: ageFilter === 'ADULTS' }"
        @click="ageFilter = 'ADULTS'"
      >
        Erwachsene
      </div>
    </div>
    <div class="workouts-list">
      <div
        class="workouts-list-item"
        v-for="item in filteredWorkouts"
        :key="item._id"
        @click="onSelect(item)"
      >
        <img
          class="avatar"
          :src="API.baseUrl + 'thumbnail?file=' + encodeURIComponent('workouts/' + item.image)"
        />
        <div class="workouts-list-item-content">
          <div class="workouts-list-item-title">
            {{ item.name }}
          </div>
          <div class="workouts-list-item-purposes">
            <div
              class="workouts-purpose-line"
              v-for="(category, index) in categories"
              :key="index + 'p'"
              :class="{
                hidden:
                  item.purposes[category.category] === 0 || category.category === 'UNSPECIFIC',
              }"
            >
              <div class="workouts-purpose-line-bar-outer">
                <div
                  class="workouts-purpose-line-bar"
                  :style="{ width: item.purposes[category.category] * 20 + '%' }"
                ></div>
              </div>
              <span class="workouts-purpose-line-label">
                {{ category.displayName }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Login",
  data() {
    return {
      ageFilter: "ADULTS",
    }
  },
  methods: {
    onSelect(item) {
      this.$store.commit("workouts/SET_SELECTED", item)
    },
  },
  computed: {
    filteredWorkouts() {
      return this.workouts.filter((o) => o.ageCategory === this.ageFilter)
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      member: "members/member",
      workouts: "workouts/workouts",
      categories: "exercises/categories",
      categoryName: "exercises/categoryName",
      levels: "workouts/levels",
      levelName: "workouts/levelName",
    }),
  },
}
</script>
