<template>
  <div class="login modal">
    <img class="login-logo" src="@/assets/gfx/kao-logo-2.png" />
    <form @submit.prevent="onLogin()" v-if="!registerMode">
      <input v-model="email" placeholder="E-Mail" />
      <input type="password" placeholder="Passwort" v-model="password" />
      <input type="submit" value="Ab ins Training" />
      <p v-if="errorMessage">{{ errorMessage }}</p>
    </form>
    <!--form @submit.prevent="onRegister()" v-if="registerMode">
      <label>Deine E-Mail</label>
      <input v-model="email">
      <label>Dein Einladungs-Code</label>
      <input type="text" v-model="inviteCode">
      <label>Wähle ein Passwort</label>
      <input type="password" v-model="password">
      <input type="submit" value="Registrieren" :disabled="password.length < 3">
      <p v-if="errorMessage">{{errorMessage}}</p>
    </form-->
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      inviteCode: "",
      registerMode: false,
      errorMessage: null,
    }
  },
  methods: {
    onLogin() {
      this.errorMessage = null
      let payload = {
        email: this.email,
        password: this.password,
      }
      this.$store.dispatch("members/login", payload).then((res) => {
        console.log(res)
        if (res.data.success) {
          console.log("onlogin success")

          this.loginVisible = false
          this.$store.commit("SET_BUSY", true)
          this.$store.dispatch("workouts/getAll").then(() => {
            this.$store.dispatch("exercises/getAll").then(() => {
              this.$store.dispatch("memberLogs/getByMember").then(() => {
                this.$store.commit("SET_BUSY", false)
                //this.$router.push({ name: 'Start' })
              })
            })
          })
        } else {
          this.errorMessage = "Das hat nicht geklappt."
        }
      })
    },
    onRegister() {
      this.errorMessage = null
      let payload = {
        email: this.email,
        password: this.password,
        inviteCode: this.inviteCode,
      }
      this.$store.dispatch("members/register", payload).then((res) => {
        console.log(res)
        if (res.data.success) {
          this.onLogin()
        } else {
          this.errorMessage =
            "Das hat nicht geklappt. Richtiger Code? Oder bist du schon registriert?"
        }
      })
    },
  },
  mounted() {
    if (window.location.href.includes("registrieren")) {
      this.registerMode = true
    }
  },
}
</script>
