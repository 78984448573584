import axios from "axios"

const state = {
  workouts: [],
  activeWorkout: null,
  selectedWorkout: null,
  ageCategories: [
    {
      category: "KIDS",
      displayName: "Kinder",
    },
    {
      category: "TEENS",
      displayName: "Jugendliche",
    },
    {
      category: "ADULTS",
      displayName: "Erwachsene",
    },
  ],
  levels: [
    {
      level: "EASY",
      displayName: "Leicht",
    },
    {
      level: "MEDIUM",
      displayName: "Mittel",
    },
    {
      level: "HARD",
      displayName: "Schwer",
    },
  ],
}

// getters
const getters = {
  workouts: (state) => state.workouts,
  selectedWorkout: (state) => state.selectedWorkout,
  activeWorkout: (state) => state.activeWorkout,
  ageCategories: (state) => state.ageCategories,
  ageCategoryName: (state) => (category) =>
    state.ageCategories.find((o) => o.category === category).displayName,
  levels: (state) => state.levels,
  levelName: (state) => (level) => state.levels.find((o) => o.level === level).displayName,
}

// actions
const actions = {
  getAll(context) {
    console.log("getAll workouts")
    return axios
      .get(context.rootGetters.API.baseUrl + "workouts", context.rootGetters.axiosConfig)
      .then((response) => context.commit("SET_ALL", response.data))
      .catch((error) => console.log(error))
  },
  create(context, payload) {
    console.log("create car")
    return axios
      .put(context.rootGetters.API.baseUrl + "workouts/", payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update(context, payload) {
    console.log("update car")
    return axios
      .patch(
        context.rootGetters.API.baseUrl + "workouts/" + payload._id,
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  delete(context, payload) {
    console.log("delete workout")
    return axios
      .delete(
        context.rootGetters.API.baseUrl + "workouts/" + payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
}

// mutations
const mutations = {
  SET_ALL(state, data) {
    state.workouts = data
  },
  SET_SELECTED(state, data) {
    state.selectedWorkout = data
  },
  SET_ACTIVE(state, data) {
    state.activeWorkout = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
