<template>
  <div class="workout-preview">
    <div class="workout-preview-header">
      <img :src="API.imageUrl + 'workouts/' + selectedWorkout.image" />
      <h1>{{ selectedWorkout.name }}</h1>
      <div class="workout-preview-header-features">
        <div class="workout-preview-header-feature">
          <div class="workout-preview-header-feature-label">
            Schwierigkeit
          </div>
          <div class="workout-preview-header-feature-value">
            {{ levelName(selectedWorkout.level) }}
          </div>
        </div>
        <div class="workout-preview-header-feature">
          <div class="workout-preview-header-feature-label">
            Dauer
          </div>
          <div class="workout-preview-header-feature-value">
            {{ selectedWorkout.totalDuration }} Minuten
          </div>
        </div>
        <div class="workout-preview-header-feature">
          <div class="workout-preview-header-feature-label">
            Schwerpunkte
          </div>
          <div class="workouts-preview-purposes">
            <div
              class="workouts-purpose-line"
              v-for="(category, index) in categories"
              :key="index + 'p'"
              :class="{
                hidden:
                  selectedWorkout.purposes[category.category] === 0 ||
                  category.category === 'UNSPECIFIC',
              }"
            >
              <div class="workouts-purpose-line-bar-outer">
                <div
                  class="workouts-purpose-line-bar"
                  :style="{ width: selectedWorkout.purposes[category.category] * 20 + '%' }"
                ></div>
              </div>
              <span class="workouts-purpose-line-label">
                {{ category.displayName }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn-primary" @click="startWorkout()">
      Workout starten
    </button>
    <button class="workout-preview-btn-back" @click="goBack()">
      Zurück
    </button>
    <div class="workout-preview-units">
      <div class="workout-preview-unit" v-for="item in selectedWorkout.units" :key="item._id">
        <img
          class="avatar"
          :src="
            API.baseUrl + 'thumbnail?file=' + encodeURIComponent('exercises/' + item.exercise.image)
          "
        />
        <div class="workout-preview-unit-title">
          {{ item.exercise.name }}
        </div>
        <div class="workout-preview-unit-instructions" v-if="item.mode === 'REPETITIONS'">
          <div class="workout-preview-unit-instructions-value">
            {{ item.repetitions }}
          </div>
          <div class="workout-preview-unit-instructions-label">
            Wiederholungen
          </div>
        </div>
        <div class="workout-preview-unit-instructions" v-if="item.mode === 'TIME'">
          <div class="workout-preview-unit-instructions-value">
            {{ item.duration }}
          </div>
          <div class="workout-preview-unit-instructions-label">
            Sekunden
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "WorkoutPreview",
  data() {
    return {
      test: "s",
    }
  },
  methods: {
    startWorkout() {
      this.$store.commit("workouts/SET_ACTIVE", this.selectedWorkout)
    },
    goBack() {
      this.$store.commit("workouts/SET_SELECTED", null)
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      member: "members/member",
      workouts: "workouts/workouts",
      activeWorkout: "workouts/activeWorkout",
      selectedWorkout: "workouts/selectedWorkout",
      categories: "exercises/categories",
      categoryName: "exercises/categoryName",
      levels: "workouts/levels",
      levelName: "workouts/levelName",
    }),
  },
}
</script>
