<template>
  <div class="header">
    <img class="logo" src="@/assets/gfx/kampfsportakademie-logo.png" @click="onLogoClick()" />
    <!--img class="title-btn-menu" src="@/assets/gfx/icon-menu.png"-->
    <div class="header-workout" v-if="activeWorkout">
      <div class="header-workout-label">Dein Workout:</div>
      <div class="header-workout-name">{{ activeWorkout.name }}</div>
    </div>
    <div class="header-member" v-if="member" @click="memberMenuVisible = !memberMenuVisible">
      <div class="header-member-name">
        <div>Angemeldet als</div>
        <div>{{ member.firstName }}&nbsp;{{ member.lastName }}</div>
      </div>
      <img v-if="member.image" :src="API.imageUrl + 'members/' + member.image" />
    </div>
    <div class="header-member-menu" v-if="memberMenuVisible">
      <button @click="logout()">Abmelden</button>
    </div>
  </div>
  <video v-show="!activeWorkout" class="intro-video" ref="introvideo" muted loop autoplay>
    <source src="videos/kao-intro.mp4" type="video/mp4" />
  </video>
  <div class="main" v-if="member && !selectedWorkout">
    <img class="main-logo" src="@/assets/gfx/kao-logo-2.png" />
    <div class="main-logs" v-if="memberLogs.length > 0">
      <h3>Deine letzten Workouts:</h3>
      <div class="main-log" v-for="item in memberLogs" :key="item._id">
        {{ formatDateTime(item.finishedAt) }}
        <span class="main-log-title">{{ item.workout.name }}</span>
      </div>
    </div>
  </div>
  <Login v-if="!member" />
  <Workouts v-if="member && !activeWorkout" />
  <WorkoutPreview v-if="member && selectedWorkout && !activeWorkout" />
  <Player v-if="member && activeWorkout" />
</template>

<script>
import { mapGetters } from "vuex"

import Player from "./components/Player.vue"
import Login from "./components/Login.vue"
import Workouts from "./components/Workouts.vue"
import WorkoutPreview from "./components/WorkoutPreview.vue"

import dateDisplayMixin from "./mixins/dateDisplay"

export default {
  name: "App",
  data() {
    return {
      memberMenuVisible: false,
    }
  },
  components: {
    Player,
    Login,
    Workouts,
    WorkoutPreview,
  },
  mixins: [dateDisplayMixin],
  mounted() {
    console.log(process.env)
  },
  methods: {
    onLogoClick() {
      if (!this.activeWorkout) {
        this.$store.commit("workouts/SET_SELECTED", null)
      }
    },
    logout() {
      this.$store.commit("workouts/SET_SELECTED", null)
      this.$store.commit("members/LOGOUT")
      this.memberMenuVisible = false
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      member: "members/member",
      activeWorkout: "workouts/activeWorkout",
      selectedWorkout: "workouts/selectedWorkout",
      memberLogs: "memberLogs/logs",
    }),
  },
}
</script>

<style>
@import "./assets/css/kao.css";
</style>
