import axios from "axios"

const state = {
  exercises: [],
  selectedExercise: null,
  categories: [
    {
      category: "UNSPECIFIC",
      displayName: "Allgemein",
    },
    {
      category: "LOWER",
      displayName: "Beine/Po",
    },
    {
      category: "CORE",
      displayName: "Core",
    },
    {
      category: "CARDIO",
      displayName: "Cardio/HIIT",
    },
    {
      category: "ARM_SHOULDER",
      displayName: "Arme/Schultern",
    },
    {
      category: "KICKBOXING",
      displayName: "Kickboxen",
    },
    {
      category: "BELT_EXAM",
      displayName: "Gürtelprüfung",
    },
    {
      category: "LOWER_EQUIPMENT",
      displayName: "Gerätetraining Beine/Po",
    },
    {
      category: "CORE_EQUIPMENT",
      displayName: "Gerätetraining Core",
    },
    {
      category: "CARDIO_EQUIPMENT",
      displayName: "Gerätetraining Cardio/HIIT",
    },
    {
      category: "ARM_SHOULDER_EQUIPMENT",
      displayName: "Gerätetraining Arm/Schulter",
    },
    {
      category: "KICKBOXING_EQUIPMENT",
      displayName: "Gerätetraining Kickboxen",
    },
  ],
}

/*
Beine/ Po
Bauch
Rücken
Brust
Cardio /HIIT
Schulter
Kickboxen
Kinder
Gürtelprüfung
Gerätetraining Beine/ Po
Gerätetraining  Bauch
Gerätetraining  Rücken
Gerätetraining Brust
Gerätetraining Cardio /HIIT
Gerätetraining Schulter
Gerätetraining Kickboxen
*/

// getters
const getters = {
  exercises: (state) => state.exercises,
  categories: (state) => state.categories,
  selectedExercise: (state) => state.selectedExercise,
  unitsTemplate: (state) => {
    let units = []
    state.exercises.forEach((exercise) => {
      let unit = {
        exercise: exercise,
        duration: 0,
        repetitions: 0,
        pauseBefore: 0,
        mode: "REPETITIONS",
      }
      units.push(unit)
    })
    return units
  },
}

// actions
const actions = {
  getAll(context) {
    return axios
      .get(context.rootGetters.API.baseUrl + "exercises", context.rootGetters.axiosConfig)
      .then((response) => context.commit("SET_EXERCISES", response.data))
      .catch((error) => console.log(error))
  },
  create(context, payload) {
    console.log("create exercise")
    return axios
      .put(context.rootGetters.API.baseUrl + "exercises/", payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update(context, payload) {
    console.log("update exercise")
    return axios
      .patch(
        context.rootGetters.API.baseUrl + "exercises/" + payload._id,
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  delete(context, payload) {
    console.log("delete exercise")
    return axios
      .delete(
        context.rootGetters.API.baseUrl + "exercises/" + payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
}

// mutations
const mutations = {
  SET_EXERCISES(state, data) {
    state.exercises = data
  },
  SET_SELECTED_EXERCISE(state, data) {
    state.selectedExercise = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
