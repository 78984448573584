
import axios from 'axios'

const state = {
  logs: []
}

// getters
const getters = {
  logs: state => state.logs
}

// actions
const actions = {
  getByMember (context) {
    return axios.get(context.rootGetters.API.baseUrl + 'memberlogs/member/' + context.rootGetters['members/member']._id, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response.data))
      .catch((error) => console.log(error))
  },
  create (context, payload) {
    console.log('create log')
    return axios.put(context.rootGetters.API.baseUrl + 'memberlogs', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
}

// mutations
const mutations = {
  SET_ALL (state, data) {
    state.logs = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
