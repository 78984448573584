<template>
  <div class="player">
    <div class="player-loading" v-if="!videoReady">
      Lade Video
    </div>
    <div class="player-countdown" v-if="videoReady && countdown > 0">
      <h1 v-if="!workoutStarted">
        Mach dich bereit
      </h1>
      <div v-if="workoutStarted">
        <div>
          Gleich:
        </div>
        <h1>
          {{activeUnit.exercise.name}}
        </h1>
        <ul>
          <li v-for="(item, index) in activeUnit.exercise.description.split('-')" :key="index">
            {{item}}
          </li>
        </ul>
      </div>
      <div class="player-countdown-number" v-if="workoutStarted">
        {{countdown}}
      </div>
      <button class="btn-primary" v-if="!workoutStarted" @click="startWorkout()">
        Ich bin bereit
      </button>
    </div>
    <div class="player-done" v-if="workoutFinished">
      <h1>Du hast es geschafft!</h1>
      <button class="btn-primary" @click="onBack()">Danke</button>
    </div>
    <div class="player-cancel" v-if="requestCancel">
      <h1>Workout abbrechen?</h1>
      <p>Deine Erfolge werden nicht gespeichert!</p>
      <div class="player-cancel-buttons">
        <button class="btn-primary" @click="requestCancel = false">Workout fortsetzen</button>
        <button @click="onBack()">Workout beenden</button>
      </div>
    </div>
    <div class="player-inner" v-if="activeUnit">
      <audio ref="audio" v-if="activeUnit.exercise.audio"
        @canplaythrough="onAudioCanplaythrough">
        <source :src="API.baseUrl + 'files/audios/' + activeUnit.exercise.audio" type="audio/mpeg">
      </audio>
      <audio ref="audioOnly5Sec">
        <source src="audio/nur_noch_5_sek.mp3" type="audio/mpeg">
      </audio>
      <audio ref="audioOnly10Sec">
        <source src="audio/nur_noch_10_sek.mp3" type="audio/mpeg">
      </audio>
      <audio ref="audioLast5Sec">
        <source src="audio/die_letzten_5_sek.mp3" type="audio/mpeg">
      </audio>
      <audio ref="audioLast10Sec">
        <source src="audio/die_letzten_10_sek.mp3" type="audio/mpeg">
      </audio>
      <video ref="video" muted
        @canplaythrough="onCanplaythrough"
        @ended="onEnded">
        <!--source :src="videoUrl" type="video/mp4"-->
      </video>
      <div class="player-training" v-if="activeUnit">
        <div class="player-training-steps">
          <div class="player-training-step" v-for="(n, index) in activeWorkout.units.length" :key="n + 'step'" :class="[{done: index < unitIndex}, {active: index === unitIndex}]"/>
        </div>
        <!--div class="player-training-step">
          {{unitIndex + 1}} / {{activeWorkout.units.length}} 
        </div-->
        <div class="player-training-name">
          {{activeUnit.exercise.name}}
        </div>
        <div class="player-training-next" v-if="nextUnit">
          <div class="player-training-next-label">
            Danach: 
          </div>
          <div class="player-training-next-name">
            {{nextUnit.exercise.name}}
          </div>
        </div>
      </div>

      <img class="player-btn-skip" src="@/assets/gfx/icon-skip.png" @click="onSkip()">
      <img class="player-btn-close" src="@/assets/gfx/icon-close.png" @click="onRequestCancelWorkout()">

      <div class="player-bar">
        <div class="player-repetitions" v-if="activeUnit.mode === 'REPETITIONS'">
          <span class="player-repetitions-count">{{activeUnit.repetitions}}</span> Wiederholungen
          <img class="player-btn-commit" src="@/assets/gfx/icon-check.png" @click="onCommitRepetitions()">
        </div>
        <div v-if="activeUnit.mode === 'TIME'"  class="player-bar-time">
          <span class="player-bar-time-value">
            {{unitSeconds}}<span class="player-bar-time-value-total">/{{activeUnit.duration}}</span>
          </span>
          <span class="player-bar-time-unit">Sekunden</span>
        </div>
        <div class="player-bar-progress" v-if="activeUnit.mode === 'TIME'">
          <div class="player-bar-progress-inner" :style="{width: unitSeconds / activeUnit.duration * 100 + '%'}"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Player',
  data () {
    return {
      videoReady: false,
      workoutStarted: false,
      workoutFinished: false,
      requestCancel: false,
      countdownInterval: null,
      INITITAL_COUNTDOWN: 10,
      countdown: 0,
      unitSeconds: 0,
      activeUnit: null,
      unitIndex: 0,
      startTime: null,
      videoUrl: null
    }
  },
  methods: {
    startWorkout () {
      this.loadVideo()
      this.workoutStarted = true
      this.startTime = new Date()
      this.startCountdown()
    },
    startCountdown () {
      let that = this
      this.countdownInterval = window.setInterval(() => {
        if (that.countdown > 0) {
          that.countdown--
        } else {
          window.clearInterval(that.countdownInterval)
          if (this.activeUnit.mode === 'TIME') {
            this.startUnitTimer()
          }
        }
      }, 1000)
    },
    startUnitTimer () {
      let that = this
      this.unitSeconds = 0
      this.unitTimerInterval = window.setInterval(() => {
        if (that.unitSeconds < that.activeUnit.duration) {
          that.unitSeconds++
          // audio motivation
          if (that.unitSeconds === that.activeUnit.duration - 10) {
            if (Math.random() > 0.5) {
              this.$refs.audioLast10Sec.play()
            } else {
              this.$refs.audioOnly10Sec.play()
            }
          }
          if (that.unitSeconds === that.activeUnit.duration - 5) {
            if (Math.random() > 0.5) {
              this.$refs.audioLast5Sec.play()
            } else {
              this.$refs.audioOnly5Sec.play()
            }
          }
        } else {
          that.goNextUnit()
        }
      }, 1000)
    },
    onCanplaythrough (e) {
      console.log('onCanplaythrough')
      console.log(e)
      /*
      this.videoReady = true
      this.$refs.video.play()
      */
    },
    onAudioCanplaythrough (e) {
      console.log('onAudioCanplaythrough')
      console.log(e)
      this.$refs.audio.play()
    },
    onEnded (e) {
      console.log('onEnded')
      console.log(e)
      this.$refs.video.play()
    },
    goNextUnit () {
      window.clearInterval(this.unitTimerInterval)
      this.unitSeconds = 0
      this.$refs.video.pause()
      if (this.unitIndex < this.activeWorkout.units.length - 1) {
        this.unitIndex++
        this.activeUnit = this.activeWorkout.units[this.unitIndex]
        console.log('setting src to:')
        console.log(this.API.baseUrl + 'files/videos/' + this.activeUnit.exercise.videoHd)
        this.$refs.video.src = null
        this.loadVideo()
        // this.$refs.video.src = this.API.baseUrl + 'files/videos/' +  this.activeUnit.exercise.videoHd
        this.$refs.audio.src = this.API.baseUrl + 'files/audios/' +  this.activeUnit.exercise.audio
        this.countdown = this.activeUnit.pauseBefore
        this.startCountdown()
      } else {
        this.workoutFinished = true
        let payload = {
          member: this.member._id,
          startedAt: this.startTime,
          finishedAt: new Date(),
          workout: this.activeWorkout._id
        }
        this.$store.dispatch('memberLogs/create', payload)
      }
    },
    onCommitRepetitions () {
      console.log('onCommitRepetitions')
      this.goNextUnit()
    },
    onBack () {
      this.workoutFinished = true
      this.$store.commit('workouts/SET_ACTIVE', null)
    },
    onRequestCancelWorkout () {
      this.requestCancel = true
    },
    onSkip () {
      if (this.unitIndex < this.activeWorkout.units.length - 1) {
        this.goNextUnit()
      } else {
        this.onRequestCancelWorkout()
      }
    },
    loadVideo () {
      this.videoReady = false
      console.log('loadVideo')
      var request = new XMLHttpRequest()
      request.open('GET', this.API.baseUrl + 'files/videos/' +  this.activeUnit.exercise.videoHd, true)
      /* set the response to blob type */
      request.responseType = "blob"
      let that = this
      request.onload = function () {
        if (this.status === 200) {
          var videoBlob = this.response
          /* create the video URL from the blob */
          var videoUrl = URL.createObjectURL(videoBlob)
          /* set the video URL as source on the video element */
          // video.src = videoUrl
          that.videoReady = true
          that.videoUrl = videoUrl
          that.$refs.video.src = videoUrl
          that.$refs.video.play()
          console.log('video LOADED')
        }
      }
      request.send()
    }
  },
  computed: {
    nextUnit () {
      if (this.activeUnit && this.unitIndex <= this.activeWorkout.units.length) {
        return this.activeWorkout.units[this.unitIndex + 1]
      } else {
        return null
      }
    },
    ...mapGetters({
      API: 'API',
      busy: 'busy',
      member: 'members/member',
      activeWorkout: 'workouts/activeWorkout',
      workouts: 'workouts/workouts',
    })
  },
  mounted () {
    this.activeUnit = this.activeWorkout.units[0]
    this.loadVideo()
    this.countdown = this.INITITAL_COUNTDOWN
  }
}
</script>

